import { API } from 'client-website-ts-library/services';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (from && from.path === to.path) return null;

    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
